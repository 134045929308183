import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";
import usePageAdminRolePermissions, {
  ExtendedOrganizationPermissions,
} from "./usePageAdminRolePermissions";

import "./pageAdminRolePermissions.scss";

const PageAdminRolePermissions = () => {
  const { t } = useTranslation();

  const {
    extendedRolePermissions,
    addPermissionToRole,
    removePermissionFromRole,
    isLoading,
  } = usePageAdminRolePermissions();

  // Role permissions table initialization
  const columlHelper = createColumnHelper<ExtendedOrganizationPermissions>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.rolePermissionId !== undefined}
            onClick={() => {
              if (info.row.original.rolePermissionId !== undefined) {
                removePermissionFromRole(info.row.original.rolePermissionId);
              } else {
                addPermissionToRole(info.row.original.id);
              }
            }}
          />
        </div>
      ),
      header: () => `${t(`common.enabled`)} / ${t(`common.disabled`)}`,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-role-permissions">
      <div className="title-role-permissions">
        {t("admin.role.role-permissions")}
      </div>
      <div className="table-role-permissions">
        <Table
          columns={columns}
          data={extendedRolePermissions ?? []}
          hasGlobalFilter={true}
        />
      </div>
    </div>
  );
};

export default PageAdminRolePermissions;
