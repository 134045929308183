import { useQuery } from "@tanstack/react-query";

import OrganizationsApi from "@user/api/OrganizationsApi";
import { Organization } from "@user/types/Organization";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { User } from "@user/types/User";
import { Permission } from "@user/types/Permission";
import { Role } from "@user/types/Role";
import { OrganizationApiRoute } from "../types/OrganizationApiRoute";
import { cacheKey } from "@/utils/constants";
import { OrganizationCompetitionRugby } from "@user/types/OrganizationCompetitionRugby";

interface useOrganizationOptions {
  skip?: boolean; // Deactivate the hook
  withUsers?: boolean; // Enable fetching the users
  usersPage?: number;
  usersPageSize?: number;
  withPermissions?: boolean; // Enable fetching the permissions
  withRoles?: boolean; // Enable fetching the roles
  rolesPage?: number;
  rolesPageSize?: number;
  withOrganizationApiRoutes?: boolean; // Enable fetching the organization API routes
  withOrganizationCompetitions?: boolean; // Enable fetching the organization competitions
}

function useOrganization(
  organizationId?: string,
  options: Partial<useOrganizationOptions> = {},
): {
  organization: Organization | undefined;
  users: PaginatedModel<User> | undefined;
  permissions: Permission[] | undefined;
  roles: PaginatedModel<Role> | undefined;
  organizationApiRoutes: OrganizationApiRoute[] | undefined;
  organizationCompetitions: OrganizationCompetitionRugby[] | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const {
    skip = false,
    withUsers = false,
    usersPage = 1,
    usersPageSize = 10,
    withPermissions = false,
    withRoles = false,
    rolesPage = 1,
    rolesPageSize = 10,
    withOrganizationApiRoutes = false,
    withOrganizationCompetitions = false,
  } = options;

  // Fetch the organization
  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useQuery({
    queryKey: [cacheKey.showOrganization, organizationId],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.show(organizationId);
    },
    enabled: !!organizationId && !skip,
  });

  // Fetch the users if required
  const {
    data: paginatedUsers,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useQuery({
    queryKey: [
      cacheKey.indexOrganizationUsers,
      organizationId,
      usersPage,
      usersPageSize,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.indexOrganizationUsers(organizationId, {
        page: usersPage,
        pageSize: usersPageSize,
      });
    },
    enabled: !!organizationId && withUsers,
  });

  // Fetch the permissions if required
  const {
    data: organizationPermissions,
    isLoading: isPermissionsLoading,
    isError: isPermissionsError,
  } = useQuery({
    queryKey: [
      cacheKey.getAllOrganizationPermissionsByOrganizationId,
      organizationId,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationPermissionsByOrganizationId(
        organizationId,
      );
    },
    enabled: !!organizationId && withPermissions,
  });

  // Fetch the roles if required
  const {
    data: paginatedRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
  } = useQuery({
    queryKey: [
      cacheKey.indexOrganizationRoles,
      organizationId,
      rolesPage,
      rolesPageSize,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.indexOrganizationRoles(organizationId, {
        page: rolesPage,
        pageSize: rolesPageSize,
      });
    },
    enabled: !!organizationId && withRoles,
  });

  // Fetch the organization api routes if required
  const {
    data: organizationApiRoutes,
    isLoading: isOrganizationApiRoutesLoading,
    isError: isOrganizationApiRoutesError,
  } = useQuery({
    queryKey: [
      cacheKey.getAllOrganizationRoutesByOrganizationId,
      organizationId,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationRoutesByOrganizationId(
        organizationId,
      );
    },
    enabled: !!organizationId && withOrganizationApiRoutes,
  });

  // Fetch the organization competition if required
  const {
    data: organizationCompetitions,
    isLoading: isOrganizationCompetitionsLoading,
    isError: isOrganizationCompetitionsError,
  } = useQuery({
    queryKey: [
      cacheKey.getAllOrganizationCompetitionsRugbyByOrganizationId,
      organizationId,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationCompetitionsRugbyByOrganizationId(
        organizationId,
      );
    },
    enabled: !!organizationId && withOrganizationCompetitions,
  });

  const isLoading =
    isOrganizationLoading ||
    isUsersLoading ||
    isPermissionsLoading ||
    isRolesLoading ||
    isOrganizationApiRoutesLoading ||
    isOrganizationCompetitionsLoading;
  const isError =
    isOrganizationError ||
    isUsersError ||
    isPermissionsError ||
    isRolesError ||
    isOrganizationApiRoutesError ||
    isOrganizationCompetitionsError;

  return {
    organization,
    users: paginatedUsers,
    permissions: organizationPermissions,
    roles: paginatedRoles,
    organizationApiRoutes: organizationApiRoutes,
    organizationCompetitions: organizationCompetitions,
    isLoading,
    isError,
  };
}

export default useOrganization;
