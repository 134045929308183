const _appEnv = import.meta.env.VITE_APP_ENV;
const _apiUrl = import.meta.env.VITE_API_URL;
const _apiMultisportsGatewayKey = import.meta.env
  .VITE_API_MULTISPORTS_GATEWAY_KEY;
const _apiCrudScopeUrl = import.meta.env.VITE_API_CRUD_SCOPE_URL;
const _adb2cMultisportsFrontClientId = import.meta.env
  .VITE_ADB2C_MULTISPORTS_FRONT_CLIENT_ID;
const _adb2cAuthorityDomain = import.meta.env.VITE_ADB2C_AUTHORITY_DOMAIN;
const _adb2cSignIn = import.meta.env.VITE_ADB2C_SIGNIN;
const _adb2cSignInAuthority = import.meta.env.VITE_ADB2C_SIGNIN_AUTHORITY;
const _aiaVideoUploadUrl = import.meta.env.VITE_AIA_VIDEO_UPLOAD_URL;
const _bunnyApiKey = import.meta.env.VITE_BUNNY_API_KEY;
const _bunnyLibraryId = import.meta.env.VITE_BUNNY_LIBRARY_ID;

// Create configuration variables to be used in the application
let appEnv: string;
let apiUrl: string;
let apiMultisportsGatewayKey: string;
let apiCrudScopeUrl: string;
let adb2cMultisportsFrontClientId: string;
let adb2cAuthorityDomain: string;
let adb2cSignIn: string;
let adb2cSignInAuthority: string;
let aiaVideoUploadUrl: string;
let bunnyApiKey: string;
let bunnyLibraryId: string;

function initConfig() {
  // Ensure that the environment variables are defined
  if (!_appEnv) {
    throw new Error("environment variable VITE_APP_ENV is not defined");
  } else {
    appEnv = _appEnv;
  }

  if (!_appEnv) {
    throw new Error("environment variable VITE_APP_ENV is not defined");
  } else {
    appEnv = _appEnv;
  }

  if (!_apiUrl) {
    throw new Error("environment variable VITE_API_URL is not defined");
  } else {
    apiUrl = _apiUrl;
  }

  if (!_apiMultisportsGatewayKey) {
    throw new Error(
      "environment variable VITE_API_MULTISPORTS_GATEWAY_KEY is not defined",
    );
  } else {
    apiMultisportsGatewayKey = _apiMultisportsGatewayKey;
  }

  if (!_apiCrudScopeUrl) {
    throw new Error(
      "environment variable VITE_API_CRUD_SCOPE_URL is not defined",
    );
  } else {
    apiCrudScopeUrl = _apiCrudScopeUrl;
  }

  if (!_adb2cMultisportsFrontClientId) {
    throw new Error(
      "environment variable VITE_ADB2C_MULTISPORTS_FRONT_CLIENT_ID is not defined",
    );
  } else {
    adb2cMultisportsFrontClientId = _adb2cMultisportsFrontClientId;
  }

  if (!_adb2cAuthorityDomain) {
    throw new Error(
      "environment variable VITE_ADB2C_AUTHORITY_DOMAIN is not defined",
    );
  } else {
    adb2cAuthorityDomain = _adb2cAuthorityDomain;
  }

  if (!_adb2cSignIn) {
    throw new Error("environment variable VITE_ADB2C_SIGNIN is not defined");
  } else {
    adb2cSignIn = _adb2cSignIn;
  }

  if (!_adb2cSignInAuthority) {
    throw new Error(
      "environment variable VITE_ADB2C_SIGNIN_AUTHORITY is not defined",
    );
  } else {
    adb2cSignInAuthority = _adb2cSignInAuthority;
  }

  if (!_aiaVideoUploadUrl) {
    throw new Error(
      "environment variable VITE_AIA_VIDEO_UPLOAD_URL is not defined",
    );
  } else {
    aiaVideoUploadUrl = _aiaVideoUploadUrl;
  }

  if (!_bunnyApiKey) {
    throw new Error("environment variable VITE_BUNNY_API_KEY is not defined");
  } else {
    bunnyApiKey = _bunnyApiKey;
  }

  if (!_bunnyLibraryId) {
    throw new Error(
      "environment variable VITE_BUNNY_LIBRARY_ID is not defined",
    );
  } else {
    bunnyLibraryId = _bunnyLibraryId;
  }
}

export {
  initConfig,
  appEnv,
  apiUrl,
  apiMultisportsGatewayKey,
  apiCrudScopeUrl,
  adb2cMultisportsFrontClientId,
  adb2cAuthorityDomain,
  adb2cSignIn,
  adb2cSignInAuthority,
  aiaVideoUploadUrl,
  bunnyApiKey,
  bunnyLibraryId,
};
