import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

import Table from "@ui-lib/table/Table";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";
import Button from "@/ui-lib/button/Button";
import Loader from "@/components/loader/Loader";
import { OrganizationGameBought } from "@user/types/OrganizationGameBought";
import usePageAdminOrganizationCompetitions, {
  ExtendedCompetition,
} from "./usePageAdminOrganizationCompetitions";

import "./pageAdminOrganizationCompetitions.scss";
import ModalOrganizationGamesBoughtByCompetition from "@user/components/ModalOrganizationGamesBoughtByCompetition/ModalOrganizationGamesBoughtByCompetition";

const PageAdminOrganizationCompetitions = () => {
  const { t } = useTranslation();
  const { id: organizationId } = useParams();
  const [isGamesModalOpen, setIsGamesModalOpen] = useState(false);
  const [organizationGamesBought, setOrganizationGamesBought] = useState<
    OrganizationGameBought[]
  >([]);

  const {
    extendedCompetitions,
    isLoading,
    isError,
    createOrganizationCompetition,
    updateOrganizationCompetition,
  } = usePageAdminOrganizationCompetitions();

  // Organization api routes table initialization
  const columlHelper = createColumnHelper<ExtendedCompetition>();
  const columns = [
    columlHelper.accessor("sport_name", {
      cell: () => (
        <>
          <p>Rugby</p>
        </>
      ),
      header: () => t("admin.sport.sports"),
      size: 30,
    }),
    columlHelper.accessor("name", {
      cell: (info) => (
        <div>
          <p>{info.row.original.name}</p>
        </div>
      ),
      header: () =>
        t("admin.organization-competitions.organization-competition-name"),
      size: 100,
    }),
    columlHelper.accessor("has_full_access", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.has_full_access}
            onClick={() => {
              info.row.original.organization_competition_id
                ? updateOrganizationCompetition({
                    id: info.row.original.organization_competition_id,
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: !info.row.original.has_full_access,
                    has_dynamic_report_access:
                      info.row.original.has_dynamic_report_access,
                    has_no_access: info.row.original.has_no_access,
                  })
                : createOrganizationCompetition({
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: !info.row.original.has_full_access,
                    has_dynamic_report_access:
                      info.row.original.has_dynamic_report_access,
                    has_no_access: info.row.original.has_no_access,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.organization-competitions.full-access"),
      size: 100,
    }),
    columlHelper.accessor("has_dynamic_report_access", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.has_dynamic_report_access}
            onClick={() => {
              info.row.original.organization_competition_id
                ? updateOrganizationCompetition({
                    id: info.row.original.organization_competition_id,
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: info.row.original.has_full_access,
                    has_dynamic_report_access:
                      !info.row.original.has_dynamic_report_access,
                    has_no_access: info.row.original.has_no_access,
                  })
                : createOrganizationCompetition({
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: info.row.original.has_full_access,
                    has_dynamic_report_access:
                      !info.row.original.has_dynamic_report_access,
                    has_no_access: info.row.original.has_no_access,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.organization-competitions.access-report-only"),
      size: 100,
    }),
    columlHelper.accessor("has_no_access", {
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.has_no_access}
            onClick={() => {
              info.row.original.organization_competition_id
                ? updateOrganizationCompetition({
                    id: info.row.original.organization_competition_id,
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: info.row.original.has_full_access,
                    has_dynamic_report_access:
                      info.row.original.has_dynamic_report_access,
                    has_no_access: !info.row.original.has_no_access,
                  })
                : createOrganizationCompetition({
                    organization_id: organizationId as string,
                    competition_id: info.row.original.id,
                    has_full_access: info.row.original.has_full_access,
                    has_dynamic_report_access:
                      info.row.original.has_dynamic_report_access,
                    has_no_access: !info.row.original.has_no_access,
                  });
            }}
          />
        </div>
      ),
      header: () => t("admin.organization-competitions.cannot-buy-games"),
      size: 100,
    }),
    columlHelper.accessor("organizationGamesBought", {
      cell: (info) => (
        <div>
          {info.row.original.organizationGamesBought &&
          info.row.original.organizationGamesBought.length > 0 ? (
            <Button
              text={t("admin.organization-competitions.show-list-of-games")}
              onClick={() => {
                setIsGamesModalOpen(true);
                if (
                  info.row.original.organizationGamesBought &&
                  info.row.original.organizationGamesBought.length > 0
                ) {
                  setOrganizationGamesBought(
                    info.row.original.organizationGamesBought,
                  );
                } else {
                  setOrganizationGamesBought([]);
                }
              }}
            />
          ) : null}
        </div>
      ),
      header: () => t("admin.organization-competitions.list-of-games"),
      size: 100,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>{t("admin.error")}</div>;
  }

  return (
    <div className="page-admin-organization-competitions">
      <div className="title-bar">
        <div className="title">
          {t("admin.organization.organization-competitions")}
        </div>
      </div>
      {isGamesModalOpen && extendedCompetitions && (
        <ModalOrganizationGamesBoughtByCompetition
          organizationGamesBought={organizationGamesBought}
          onClose={() => setIsGamesModalOpen(false)}
        />
      )}
      <Table
        columns={columns}
        data={extendedCompetitions ?? []}
        hasGlobalFilter={true}
        maxHeight="75vh"
      />
    </div>
  );
};

export default PageAdminOrganizationCompetitions;
