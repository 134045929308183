import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import useOrganization from "@user/hooks/useOrganization";
import { APIError } from "@user/types/ApiError";
import { Organization } from "@user/types/Organization";
import { cacheKey } from "@/utils/constants";
import OrganizationsApi from "@user/api/OrganizationsApi";
import usePaginatedTokenOperations from "@user/hooks/usePaginatedTokenOperations";
import { TokenOperations } from "@user/types/TokenOperation";
import { PaginatedModel } from "@user/types/PaginatedModel";

function usePageAdminOrganizationTokens(): {
  organization: Organization | undefined;
  refillTokens: (number_of_tokens: number) => void;
  isLoading: boolean;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  paginatedTokenOperations: PaginatedModel<TokenOperations> | undefined;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setCurrentPageSize: (pageSize: number) => void;
  pageSize: number;
} {
  const { id: organizationId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsEditModalOpen] = useState(false);

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setCurrentPageSize] = useState(10);

  // Fetch the organization
  const { organization, isLoading: isOrganizationLoading } = useOrganization(
    organizationId,
    { skip: !organizationId },
  );

  // Get the paginated token operations
  const { paginatedTokenOperations, isLoading: isTokenOperationsLoading } =
    usePaginatedTokenOperations({
      organizationId: organizationId,
      page: currentPage,
      pageSize,
    });

  // Update organization tokenoperation
  const { mutate: refillTokensByOrganizationId } = useMutation({
    mutationFn: organizationId
      ? (number_of_tokens: number) =>
          OrganizationsApi.refillTokensByOrganizationId(
            organizationId,
            number_of_tokens,
          )
      : undefined,
    onSuccess: (data: Organization) => {
      queryClient.invalidateQueries({
        queryKey: [cacheKey.showOrganization, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizations],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizationTokenOperations, organizationId],
      });
      toast.success(
        t("admin.organization-tokens.organization-refill-tokens-success"),
        {
          autoClose: 3000,
        },
      );
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t("admin.organization-tokens.organization-refill-tokens-failed"),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function refillTokens(number_of_tokens: number) {
    refillTokensByOrganizationId(number_of_tokens);
  }

  // Open the edit modal
  function openModal() {
    setIsEditModalOpen(true);
  }

  function closeModal() {
    setIsEditModalOpen(false);
  }

  const isLoading = isTokenOperationsLoading || isOrganizationLoading;

  return {
    organization,
    refillTokens,
    isLoading,
    isModalOpen,
    openModal,
    closeModal,
    paginatedTokenOperations,
    setCurrentPage,
    currentPage,
    setCurrentPageSize,
    pageSize,
  };
}

export default usePageAdminOrganizationTokens;
