import axios from "axios";

import { Option } from "@pelote/types/Option";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { getDefaultHeaders } from "./utils";

export interface CreateOptionInputs {
  name: string;
  sport_id: string;
}

export interface UpdateOptionInputs {
  name: string;
  sport_id: string;
}

export default class OptionsApi {
  static resource = "options";

  static async create(createOptionInputs: CreateOptionInputs): Promise<Option> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createOptionInputs,
    });
    return response.data;
  }

  static async index(
    page: number = 1,
    page_size: number = 10,
    sport_id?: string,
  ): Promise<PaginatedModel<Option>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}?page=${page}&page_size=${page_size}`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Option> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(
    id: string,
    updateOptionInputs: UpdateOptionInputs,
  ): Promise<Option> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateOptionInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
