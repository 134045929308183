import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { createColumnHelper } from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";

import Button from "@/ui-lib/button/Button";
import Table from "@/ui-lib/table/Table";
import Loader from "@/components/loader/Loader";
import Pagination from "@/ui-lib/pagination/Pagination";

import {
  convertISODateToDisplayFormat,
  convertISODateToDisplayFormatDateOnly,
} from "@/utils/convertISODateToDisplayFormat";
import {
  TokenOperations,
  TokenOperationType,
} from "@user/types/TokenOperation";
import ModalAddOrganizationTokens from "@user/components/ModalAddOrganizationTokens/ModalAddOrganizationTokens";
import usePageAdminOrganizationTokens from "./usePageAdminOrganizationTokens";

import "./pageAdminOrganizationTokens.scss";

const PageAdminOrganizationTokens = () => {
  const { t } = useTranslation();

  const {
    organization,
    refillTokens,
    isLoading,
    isModalOpen,
    openModal,
    closeModal,
    paginatedTokenOperations,
    setCurrentPage,
    currentPage,
    pageSize,
  } = usePageAdminOrganizationTokens();

  const columlHelper = createColumnHelper<TokenOperations>();
  const columns = [
    columlHelper.accessor("created_at", {
      cell: (info) => (
        <div>
          <p>{convertISODateToDisplayFormat(info.row.original.created_at)}</p>
        </div>
      ),
      header: () => t("admin.organization-tokens.date"),
      size: 30,
    }),
    columlHelper.accessor("operation_type", {
      cell: (info) => {
        const operationType = info.row.original.operation_type;
        return (
          <div>
            <p>
              {operationType === TokenOperationType.REFILL &&
                t(`admin.organization-tokens.refill`)}
              {operationType === TokenOperationType.BUY_RUGBY_GAME &&
                t(`admin.organization-tokens.buy-rugby-game`)}
              {operationType === TokenOperationType.BUY_RUGBY_PLAYER &&
                t(`admin.organization-tokens.buy-rugby-player`)}
              {operationType === TokenOperationType.BUY_RUGBY_TEAM &&
                t(`admin.organization-tokens.buy-rugby-team`)}
            </p>
          </div>
        );
      },
      header: () => t("admin.organization-tokens.operation-type"),
      size: 50,
    }),
    columlHelper.accessor("game_rugby", {
      cell: (info) => (
        <div>
          {info.row.original.game_rugby ? (
            <div
              data-tooltip-id="idCompetitionAndDateTooltip"
              data-tooltip-content={`${info.row.original.game_rugby.competition.name.toUpperCase()} ${convertISODateToDisplayFormatDateOnly(info.row.original.game_rugby.date)}`}
            >
              <p>
                {info.row.original.game_rugby.team_home.name} -{" "}
                {info.row.original.game_rugby.team_away.name}
              </p>
              <Tooltip
                id="idCompetitionAndDateTooltip"
                className="idCompetitionAndDateTooltip"
                classNameArrow="idCompetitionAndDateTooltipArrow"
              />
            </div>
          ) : (
            <p> - </p>
          )}
        </div>
      ),
      header: () => t("admin.organization-tokens.entity"),
      size: 100,
    }),
    columlHelper.accessor("token_variation", {
      cell: (info) => (
        <div>
          <p>{info.row.original.token_variation}</p>
        </div>
      ),
      header: () => t("admin.organization-tokens.token-variation"),
      size: 20,
    }),
    columlHelper.accessor("user", {
      cell: (info) => (
        <div
          data-tooltip-id="idCompetitionAndDateTooltip"
          data-tooltip-content={`${info.row.original.user.role.name} ${info.row.original.user.email}`}
        >
          <p>
            {info.row.original.user.last_name}{" "}
            {info.row.original.user.first_name}
          </p>
          <Tooltip
            id="idCompetitionAndDateTooltip"
            className="idCompetitionAndDateTooltip"
            classNameArrow="idCompetitionAndDateTooltipArrow"
          />
        </div>
      ),
      header: () => t("admin.organization-tokens.user"),
      size: 40,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-tokens">
      {isModalOpen && (
        <Fragment>
          <ModalAddOrganizationTokens
            organizationName={organization?.name ?? ""}
            refillTokens={refillTokens}
            onClose={closeModal}
          />
        </Fragment>
      )}
      <div className="title-bar">
        <div className="title">
          {t("admin.organization-tokens.organization-tokens")}
        </div>
      </div>
      <div className="nb-add-tokens-container">
        <div className="title">
          {t("admin.organization-tokens.number-of-remaining-tokens")}:{" "}
          {organization?.number_of_tokens}
        </div>
        <div>
          <Button
            text={t(`admin.organization-tokens.add-tokens`)}
            onClick={openModal}
          />
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          data={paginatedTokenOperations?.items ?? []}
          hasGlobalFilter={true}
          maxHeight="75vh"
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedTokenOperations?.pagination.total_items ?? 0}
            itemsPerPage={pageSize}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminOrganizationTokens;
