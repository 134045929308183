import axios from "axios";

import { domain } from "@pelote/config";
import { Competition, SetsPatterns } from "@pelote/types/Competition";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { getDefaultHeaders } from "./utils";

export interface CreateCompetitionInputs {
  name: string;
  description: string;
  sets_pattern: SetsPatterns;
  sport_id: string;
  logo?: File;
}

export interface UpdateCompetitionInputs {
  name: string;
  description: string;
  sets_pattern: SetsPatterns;
  sport_id: string;
  logo?: File;
}

export default class CompetitionsApi {
  static resource = "competitions";

  static async create(
    createCompetitionInputs: CreateCompetitionInputs,
  ): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}`;
    // Need to use FormData to send the logo of competition
    const formData = new FormData();

    for (const [key, value] of Object.entries(createCompetitionInputs)) {
      if (key === "logo" && value instanceof FileList) {
        const file = value.item(0); // Get the first file from the FileList
        if (file) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, value);
      }
    }

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: formData,
    });
    return response.data;
  }

  static async index(
    page: number = 1,
    pageSize: number = 10,
    sport_id?: string,
  ): Promise<PaginatedModel<Competition>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}?page=${page}&page_size=${pageSize}`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(
    id: string,
    updateCompetitionInputs: UpdateCompetitionInputs,
  ): Promise<Competition> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;

    // Need to use FormData to send the logo of competition
    const formData = new FormData();

    for (const [key, value] of Object.entries(updateCompetitionInputs)) {
      if (key === "logo" && value instanceof FileList) {
        const file = value.item(0); // Get the first file from the FileList
        if (file) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, value);
      }
    }

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: formData,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async delete_logo(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}/logo`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
