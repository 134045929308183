import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationApiAccesses, {
  ExtendedRoute,
} from "./usePageAdminOrganizationApiAccesses";
import FormButton from "@/ui-lib/form-button/FormButton";
import ModalEditOrganizationApiRoute from "@user/components/ModalEditOrganizationApiRoute/ModalEditOrganizationApiRoute";

import "./pageAdminOrganizationApiAccesses.scss";

const PageAdminOrganizationApiAccesses = () => {
  const { t } = useTranslation();

  const {
    organization,
    extendedRoutes,
    createOrganizationApiRoute,
    deleteOrganizationApiRoute,
    isLoading,
    isEditModalOpen,
    openEditModal,
    closeEditModal,
    selectedExtendedRoute,
    updateOrganizationRoute,
  } = usePageAdminOrganizationApiAccesses();

  // Organization api routes table initialization
  const columlHelper = createColumnHelper<ExtendedRoute>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.domain.domain"),
      size: 50,
    }),
    columlHelper.accessor("method", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.method"),
      size: 50,
    }),
    columlHelper.accessor("path", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.path"),
      size: 160,
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.description"),
      size: 160,
    }),
    columlHelper.accessor("filter", {
      cell: (info) => info.getValue(),
      header: () => t("admin.organization-api-route.filter"),
      size: 160,
    }),
    columlHelper.display({
      id: "actions",
      size: 120,
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => {
              if (!info.row.original.organizationRouteId) return;
              openEditModal(info.row.original.id);
            }}
            disabled={!info.row.original.organizationRouteId}
          />
          <FormButton
            labelName={t("admin.organization-api-route.enabled")}
            color={info.row.original.is_active ? "green" : undefined}
            onClick={() => {
              if (!info.row.original.is_active) {
                if (!organization?.id) {
                  return;
                }
                createOrganizationApiRoute({
                  organizationId: organization?.id,
                  routeId: info.row.original.id,
                  filter: info.row.original.filter,
                });
              }
            }}
          />
          <FormButton
            labelName={t("admin.organization-api-route.disabled")}
            color={!info.row.original.is_active ? "red" : undefined}
            onClick={() => {
              if (
                info.row.original.is_active &&
                info.row.original.organizationRouteId
              ) {
                deleteOrganizationApiRoute(
                  info.row.original.organizationRouteId,
                );
              }
            }}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-api-accesses">
      {isEditModalOpen && selectedExtendedRoute && (
        <ModalEditOrganizationApiRoute
          onClose={closeEditModal}
          extendedRoute={selectedExtendedRoute}
          updateOganizationRoute={updateOrganizationRoute}
        />
      )}
      <div className="title-bar">
        <div className="title">
          {t("admin.organization.organization-api-accesses")}
        </div>
      </div>
      <Table
        columns={columns}
        data={extendedRoutes ?? []}
        hasGlobalFilter={true}
        maxHeight="75vh"
      />
    </div>
  );
};

export default PageAdminOrganizationApiAccesses;
