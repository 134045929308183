import axios from "axios";

import { getDefaultHeaders } from "./utils";
import { User, UserFull } from "@user/types/User";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Role } from "@user/types/Role";
import { Sport } from "@user/types/Sport";

export interface CreateUserInputs {
  first_name: string;
  last_name: string;
  email: string;
  organization_id: string; // UUID
  role_id: string; // UUID
}

export interface UpdateUserInputs {
  first_name?: string;
  last_name?: string;
  email?: string;
  provider_id?: string; // UUID
  is_active?: boolean;
  organization_id?: string; // UUID;
  role_id?: string; // UUID;
}

export interface IndexUserOptions {
  lastName?: string;
  organizationName?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export interface IndexUserRoles {
  page?: number;
  pageSize?: number;
}

export interface IndexUserSports {
  page?: number;
  pageSize?: number;
}

export default class UsersApi {
  static domain: string = "user";
  static resource: string = "users";

  static async create(createUserInputs: CreateUserInputs): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}`;

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createUserInputs,
    });

    return response.data;
  }

  static async show(id: string): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async showMe(): Promise<UserFull> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/me`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async index(
    options?: Partial<IndexUserOptions>,
  ): Promise<PaginatedModel<User>> {
    const {
      lastName = "",
      organizationName = "",
      page = 1,
      pageSize = 10,
      sortBy = "last_name:asc",
    } = options || {};

    // Build base URL
    const url = new URL(
      `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}`,
    );

    // Add query params
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (sortBy) queryParams.append("sort_by", sortBy);
    if (lastName) queryParams.append("last_name", lastName);
    if (organizationName)
      queryParams.append("organization_name", organizationName);

    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const defaultHeaders = await getDefaultHeaders();

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async update(
    id: string,
    updateUserInputs: UpdateUserInputs,
  ): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateUserInputs,
    });

    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexUserRoles(
    userId: string,
    options: IndexUserRoles,
  ): Promise<PaginatedModel<Role>> {
    const { page = 1, pageSize = 10 } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/roles?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async addRole(userId: string, roleId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/roles/${roleId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removeRole(userId: string, roleId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/roles/${roleId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexUserSports(
    userId: string,
    options: IndexUserSports,
  ): Promise<PaginatedModel<Sport>> {
    const { page = 1, pageSize = 10 } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/sports?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async addSport(userId: string, sportId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/sports/${sportId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removeSport(userId: string, sportId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/sports/${sportId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async sendWelcomeEmail(userId: string): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${userId}/email/welcome`;

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }
}
