import { toast } from "react-toastify";
import { t } from "i18next";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useRole from "@user/hooks/useRole";
import { Permission } from "@user/types/Permission";
import RolesApi from "@user/api/RolesApi";
import { APIError, APIErrorType } from "@user/types/ApiError";
import useOrganization from "@user/hooks/useOrganization";
import { cacheKey } from "@/utils/constants";

/**
 * This interface is used to extend the OrganizationPermission with the rolePermissionId if any
 */
export interface ExtendedOrganizationPermissions extends Permission {
  rolePermissionId?: string;
}

function usePageAdminRolePermissions(): {
  extendedRolePermissions: ExtendedOrganizationPermissions[] | undefined;
  addPermissionToRole: (permissionId: string) => void;
  removePermissionFromRole: (permissionId: string) => void;
  isLoading: boolean;
} {
  const { id: roleId } = useParams();

  // Get the role permissions
  const {
    role,
    permissions: rolePermissions,
    isLoading: isRolePermissionsLoading,
  } = useRole(roleId, {
    withPermissions: true,
  });

  // Get the organization permissions
  const {
    permissions: organizationPermissions,
    isLoading: isPermissionsLoading,
  } = useOrganization(role?.organization.id, {
    withPermissions: true,
  });

  // Build the extended permissions
  const extendedRolePermissions = organizationPermissions?.map((permission) => {
    const rolePermission = rolePermissions?.find(
      (rolePermission) => rolePermission.id === permission.id,
    );

    return {
      ...permission,
      rolePermissionId: rolePermission?.id,
    };
  });

  // Add a permission to the role
  const queryClientAdd = useQueryClient();
  const { mutate: addPermissionToRoleInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!roleId) return new Promise(() => {});
      return RolesApi.addPermission(roleId, permissionId);
    },
    onSuccess: () => {
      queryClientAdd.invalidateQueries({
        queryKey: [cacheKey.getAllRolePermissionsByRoleId, roleId],
      });

      toast.success(t("admin.role.permissions-assignation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.role.permissions-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.role.permissions-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addPermissionToRole(permissionId: string) {
    addPermissionToRoleInApi(permissionId);
  }

  // Remove a permission from the role
  const queryClientRemove = useQueryClient();
  const { mutate: removePermissionFromRoleInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!roleId) return new Promise(() => {});
      return RolesApi.removePermission(roleId, permissionId);
    },
    onSuccess: () => {
      queryClientRemove.invalidateQueries({
        queryKey: [cacheKey.getAllRolePermissionsByRoleId, roleId],
      });

      toast.success(t("admin.role.permissions-removal-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      console.error(error);
      toast.error(t("admin.role.permissions-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removePermissionFromRole(permissionId: string) {
    removePermissionFromRoleInApi(permissionId);
  }

  // Build the global isLoading flag
  const isLoading = isRolePermissionsLoading || isPermissionsLoading;

  return {
    extendedRolePermissions,
    addPermissionToRole,
    removePermissionFromRole,
    isLoading,
  };
}

export default usePageAdminRolePermissions;
